import axios from 'axios'; 

const url = "https://sagradabiblia1602.com/"; 
const Email =  {
    contactEmail: async (email) => {
        let value = await axios.post(`${url}email/email.php`, {address: email.address, message: email.message})
        .then(data => {return data}).catch((error)=> {return error}); 
        return value; 
    },
    
}

export default Email; 

